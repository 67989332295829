.legend {
  margin-top: 20px;
}

.legend div {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.legend span {
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 4px; /* Para hacer las esquinas redondeadas */
}