#spinner-modal-background {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: left;
    background: rgba(54, 52, 52, 0.85);
    z-index: 9999;
}
.content-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
}
.show-spinner{
    display: block;
}
.hidden-spinner{
    display: none;
}